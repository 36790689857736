<template>
  <div class="my-course" ref="course" v-if="course">
    <course-mobile-navigation
      @switch-lesson="switchLesson($event)"
      @set-lesson="setLesson($event)"
      :lessons="courseProgram"
      :active-lesson="activeLesson"
      :progress="courseProgress"
      :details="details"
      :generateCertificate="generateCertificate"
      :courseId="course.id"
      :isCompleted="courseProgress === 100"
      :isValidCertificate="isValidCertificate"
    />

    <div class="my-course__inner container container--big">
      <div class="my-course__inner-left">
        <div class="my-course__inner-video">
          <transition name="fade" mode="out-in">
            <video-loader v-if="!videoLoading"/>
            <course-video-player
              v-else
              :video-poster="imageLink"
              :videoID="activeLesson ? activeLesson.assetId : ''"
              :lessonID="activeLesson ? activeLesson.id : ''"
              :courseID="course.id"
              :captions="currentCaptions"
              :sources="activeSources"
              @lesson-completed="setLessonAsCompleted($event)"
              mode="bought"
            />
          </transition>
        </div>
        <div style="color: gray; margin-top: 10px;">Problems with video?
          Describe your problem <a
            :href="'mailto:info@edunails.com?subject=course '+course.slug+' problem'">here</a>
        </div>

        <div class="my-course-content">
          <div class="my-course-content__inner">
            <section class="my-course-content__section">
              <base-sub-heading>
                {{ $t("course.about") }}
              </base-sub-heading>

              <base-heading class="my-course-content__section-heading">
                {{
                  !activeDescription
                    ? $t("course.description")
                    : $t("boughtCourse.lessonDescription")
                }}
              </base-heading>

              <p class="my-course-content__description">
                {{ activeDescription ? activeDescription : course.description }}
              </p>
            </section>
          </div>
        </div>
      </div>

      <div class="my-course__inner-right">
        <course-actions
          :course="course"
          :lessons="courseProgram"
          :details="details"
          :active-lesson="activeLesson"
          :progress="courseProgress"
          @set-lesson="setLesson($event)"
          :isCompleted="courseProgress === 100"
          :generateCertificate="generateCertificate"
          :isValidCertificate="isValidCertificate"
        />
      </div>
    </div>
  </div>
</template>

<script>
import course from '@/api/services/course';
import video from '@/api/upload/video';
import { getImageLink, isValidCertificate } from '@/helpers';

export default {
  name: 'BoughtCoursePage',
  metaInfo() {
    return {
      title: `Edunails - ${this.course.name} course`,
      titleTemplate: null,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: this.course.description,
        },
      ],
    };
  },
  data: () => ({
    lessonStatus: false,
    activeSources: [],
    activeLesson: null,
    activeDescription: null,
    videoLoading: false,
    course: false,
    details: false,
    mobileLessonStatus: false,
    currentCaptions: [],
    captionsError: false,
    courseProgram: [],
  }),
  watch: {
    activeLesson() {
      this.getCaptions();
      this.getVideoSources();
    },
  },
  methods: {
    openVideo() {
      this.scrollTo();
    },
    async generateCertificate(isPortalCertificate) {
      this.$store.dispatch('ui/setPreloader', true);

      try {
        const { data } = await course.getCertificate(
          this.details.id,
          isPortalCertificate,
        );
        const blob = new Blob([data], { type: 'image/png' });
        const fileURL = URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = fileURL;
        link.download = 'certificate';
        document.body.append(link);

        link.click();
        link.remove();
      } catch (err) {
        console.log(err);
        this.$store.dispatch('notification/spawn', {
          errorMessage: err.response.data.message,
          type: 'error',
        });
      } finally {
        this.$store.dispatch('ui/setPreloader', false);
      }
    },
    scrollTo() {
      const target = document.querySelector('body');

      if (target) {
        target.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    },
    setLesson(payload) {
      this.activeLesson = payload;
      this.activeDescription = payload.description;
    },
    switchLesson(type) {
      // if (!this.activeLesson) {
      //   this.setLesson()
      // }

      // if

      // if (!this.activeLesson) {
      //   this.setLesson(lessons[0]];

      //   return;
      // }
      type === 'next' ? this.nextLesson() : this.prevLesson();
    },
    nextLesson() {
      const currentLessonIndex = this.courseProgram.indexOf(this.activeLesson);
      const courseProgramLessonsLength = this.courseProgram.length;

      if (currentLessonIndex + 1 !== courseProgramLessonsLength) {
        this.setLesson(this.courseProgram[currentLessonIndex + 1]);
      }

      if (currentLessonIndex === courseProgramLessonsLength) {
        this.setLesson(this.courseProgram[courseProgramLessonsLength]);
      }
    },
    prevLesson() {
      const currentLessonIndex = this.courseProgram.indexOf(this.activeLesson);

      if (currentLessonIndex !== 0) {
        this.setLesson(this.courseProgram[currentLessonIndex - 1]);
      }
    },
    toggleMobileLessons() {
      this.mobileLessonStatus = !this.mobileLessonStatus;
    },
    async getVideoSources() {
      this.activeSources = [];

      try {
        const { data } = await video.getAllVideoSources({
          courseID: this.activeLesson.courseId,
          lessonID: this.activeLesson.id,
        });

        Object.keys(data).forEach((key) => {
          if (key !== 'Id') {
            const qualityKey = key.substring(5);

            this.activeSources.push({
              src: data[key],
              type: 'video/mp4',
              label: key.substring(5),
              quality: parseInt(qualityKey.substring(0, qualityKey.length - 1)),
            });
          }
        });
      } catch (err) {
        console.log(err);
      }
    },
    async getCaptions() {
      this.videoLoading = false;

      try {
        const { data } = await video.getVideoSubtitles({
          courseId: this.course.id,
          lessonId: this.activeLesson.id,
        });

        this.currentCaptions = data;
        this.videoLoading = true;
      } catch (err) {
        this.captionError = true;
      }
    },
    isCompleted(lesson = {}) {
      const lessons = this.watchedLessons;
      return lessons.some((watchedLesson) => watchedLesson === lesson.id);
    },
    setLessonAsCompleted({ lessonId }) {
      this.courseProgram = this.courseProgram.map((lesson) => {
        if (lesson.id === lessonId) {
          return {
            ...lesson,
            isCompleted: true,
          };
        }

        return lesson;
      });
    },
    setProgram() {
      const { lessons } = this.course;

      // Add isComplited status for each lesson
      const editedLessons = lessons.map((lesson) => {
        return {
          ...lesson,
          isCompleted: this.isCompleted(lesson),
        };
      });

      // Sort lessons by sequnceNumber
      this.courseProgram = editedLessons.sort((lessonA, lessonB) => {
        return lessonA.sequenceNumber > lessonB.sequenceNumber ? 1 : -1;
      });
    },
  },
  computed: {
    imageLink() {
      return getImageLink(this.course.imageId);
    },
    courseProgress() {
      const watchedLessonsLength = this.watchedLessonsAfterLoad
        ? this.watchedLessonsAfterLoad
        : 0;
      const lessonsLength = this.course ? this.course.lessons.length : 0;

      const calculation = watchedLessonsLength / lessonsLength;
      return Math.round(calculation * 100);
    },
    watchedLessons() {
      return this.details.completedLessons;
    },
    isValidCertificate() {
      return isValidCertificate(this.course);
    },
    watchedLessonsAfterLoad() {
      let watchedLessonsLength = 0;

      this.courseProgram.forEach((lesson) => {
        lesson.isCompleted ? (watchedLessonsLength += 1) : false;
      });

      return watchedLessonsLength;
    },
    willLearn() {
      return this.course.willLearn.split(',');
    },
    needs() {
      return this.course.needs.split(',');
    },
    isAvailable() {
      return this.details && this.details.paymentStatus === 'paid';
    },
  },
  async created() {
    this.$store.dispatch('ui/setPreloader', true);
    const { id } = this.$route.params;

    try {
      const { data } = await course.getOwnedCourse(id);
      this.course = data.course;
      this.details = data;

      if (!this.isAvailable) {
        this.$router.push('/404');
      }

      this.setProgram();

      // Set first chapter
      this.setLesson(this.courseProgram[0]);

      // Get all captions & video sources based on chapter
      this.getCaptions();
    } catch (err) {
      this.$store.dispatch('notification/spawn', {
        errorMessage: err.response.data.message,
        type: 'error',
      });

      this.$router.push('/404');
    } finally {
      this.$store.dispatch('ui/setPreloader', false);
    }
  },
};
</script>

<style lang="scss" src="./MyCourse.scss"/>
